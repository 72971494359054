var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.displayInputIcon)?_c('validation-provider',{attrs:{"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-2",attrs:{"readonly":"","label":_vm.label,"placeholder":_vm.placeholder,"error-messages":errors,"disabled":_vm.disabled},model:{value:(_vm.selectedTextFieldColor),callback:function ($$v) {_vm.selectedTextFieldColor=$$v},expression:"selectedTextFieldColor"}},'v-text-field',attrs,false),on),[_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({style:({ color: _vm.selectedTextFieldColor }),attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.menu = !_vm.menu}}},on),[_vm._v(" mdi-palette ")])]}}],null,true)},[_c('span',[_vm._v("Clique aqui para modificar a cor")])])],1)]}}],null,true)}):_c('v-select',_vm._g(_vm._b({attrs:{"hint":"Esse campo é opcional","persistent-hint":"","color":"primary","clearable":"","items":_vm.arrIcones,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"change":function($event){return _vm.$emit(
            'iconSelected',
            _vm.selectedTextIcon ? _vm.selectedTextIcon : undefined
          )}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"size":"30px","color":"black","left":""}},[_vm._v(" "+_vm._s(item)+" ")]),_vm._v(" "+_vm._s(_vm.formatNameIcon(item))+" ")]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"size":"30px","color":"black","left":""}},[_vm._v(" "+_vm._s(item)+" ")]),_vm._v(" "+_vm._s(_vm.formatNameIcon(item))+" ")]}}],null,true),model:{value:(_vm.selectedTextIcon),callback:function ($$v) {_vm.selectedTextIcon=$$v},expression:"selectedTextIcon"}},'v-select',attrs,false),on),[_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({style:({ color: _vm.selectedTextFieldColor }),attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.menu = !_vm.menu}}},on),[_vm._v(" mdi-palette ")])]}}],null,true)},[_c('span',[_vm._v("Clique aqui para modificar a cor")])])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-color-picker',{attrs:{"dot-size":"25","hide-mode-switch":"","mode":"hexa","swatches-max-height":"200","disabled":_vm.disabled},on:{"input":_vm.handleColorPickerInput},model:{value:(_vm.selectedPickerColor),callback:function ($$v) {_vm.selectedPickerColor=$$v},expression:"selectedPickerColor"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }