<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <validation-provider
          v-if="!displayInputIcon"
          v-slot="{ errors }"
          :rules="rules"
        >
          <v-text-field
            v-model="selectedTextFieldColor"
            v-bind="attrs"
            v-on="on"
            class="pt-2"
            readonly
            :label="label"
            :placeholder="placeholder"
            :error-messages="errors"
            :disabled="disabled"
          >
            <v-tooltip
              slot="append-outer"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :style="{ color: selectedTextFieldColor }"
                  @click="menu = !menu"
                  :disabled="disabled"
                >
                  mdi-palette
                </v-icon>
              </template>
              <span>Clique aqui para modificar a cor</span>
            </v-tooltip>
          </v-text-field>
        </validation-provider>
        <v-select
          v-else
          v-model="selectedTextIcon"
          v-bind="attrs"
          v-on="on"
          hint="Esse campo é opcional"
          persistent-hint
          color="primary"
          clearable
          :items="arrIcones"
          :placeholder="placeholder"
          :disabled="disabled"
          @change="
            $emit(
              'iconSelected',
              selectedTextIcon ? selectedTextIcon : undefined
            )
          "
        >
          <template v-slot:selection="{ item }">
            <v-icon
              size="30px"
              color="black"
              left
            >
              {{ item }}
            </v-icon>
            {{ formatNameIcon(item) }}
          </template>
          <template v-slot:item="{ item }">
            <v-icon
              size="30px"
              color="black"
              left
            >
              {{ item }}
            </v-icon>
            {{ formatNameIcon(item) }}
          </template>
          <v-tooltip
            slot="append-outer"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                :style="{ color: selectedTextFieldColor }"
                @click="menu = !menu"
                :disabled="disabled"
              >
                mdi-palette
              </v-icon>
            </template>
            <span>Clique aqui para modificar a cor</span>
          </v-tooltip>
        </v-select>
      </template>
      <v-color-picker
        v-model="selectedPickerColor"
        @input="handleColorPickerInput"
        dot-size="25"
        hide-mode-switch
        mode="hexa"
        swatches-max-height="200"
        :disabled="disabled"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    displayInputIcon: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: 'Cor'
    },
    placeholder: {
      type: String,
      default: 'selecione um ícone'
    },
    selectedColor: {
      type: String,
      default: null
    },
    selectedIcon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default() {
        return {
          min: 7
        };
      }
    }
  },
  mounted() {
    if (this.selectedColor) this.selectedTextFieldColor = this.selectedColor;

    if (this.selectedIcon) this.selectedTextIcon = this.selectedIcon;
  },
  data: () => ({
    selectedTextFieldColor: null,
    selectedTextIcon: '',
    selectedPickerColor: null,
    menu: false,
    iconeSelecionado: '',
    arrIcones: [
      'mdi-alert',
      'mdi-battery-high',
      'mdi-battery-medium',
      'mdi-cable-data',
      'mdi-car-battery',
      'mdi-city',
      'mdi-city-variant-outline',
      'mdi-office-building',
      'mdi-office-building-marker',
      'mdi-currency-kzt',
      'mdi-currency-mnt',
      'mdi-domain',
      'mdi-flash',
      'mdi-lightbulb-outline',
      'mdi-electric-switch',
      'mdi-electric-switch-closed',
      'mdi-fuse',
      'mdi-fuse-alert',
      'mdi-fuse-blade',
      'mdi-map-marker-radius',
      'mdi-map-marker-radius-outline',
      'mdi-outdoor-lamp',
      'mdi-power-plug',
      'mdi-power-plug-outline',
      'mdi-railroad-light',
      'mdi-screw-machine-round-top',
      'mdi-sign-pole',
      'mdi-spotlight-beam',
      'mdi-video-input-antenna',
      'mdi-wind-turbine'
    ]
  }),
  methods: {
    handleColorPickerInput(e) {
      this.selectedTextFieldColor = e.hex;
    },
    formatNameIcon(name) {
      return name.replace('mdi-', '');
    }
  },
  watch: {
    menu() {
      if (this.menu === false) {
        this.$emit('colorSelected', this.selectedTextFieldColor);
      }
    },
    selectedColor() {
      if (this.selectedColor) {
        this.selectedTextFieldColor = this.selectedColor;
      }
    },
    selectedIcon() {
      this.selectedTextIcon = this.selectedIcon;
    }
  }
};
</script>
